
const MENU_BUTTON_OPEN = document.querySelector('#menu-open');
const MENU_BUTTON_CLOSE = document.querySelector('#menu-close');
const MOBILE_MENU = document.querySelector('#mobile-menu');

MENU_BUTTON_OPEN.addEventListener('click', () => {
    MOBILE_MENU.classList.add('show')
});

MENU_BUTTON_CLOSE.addEventListener('click', () => {
    window.scrollTo(0, 0);
    MOBILE_MENU.classList.remove('show');
});